import CaptureWrapper from './components/CaptureWrapper/CaptureWrapper';
import DamageReview from './components/DamageReview/DamageReview';
import SkipButton from './components/SkipButton/SkipButton';
import * as Sentry from '@sentry/react';
import { useLiveQuery } from 'dexie-react-hooks';
import { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEntityConfigStore } from 'src/stores/entityConfig';
import { useFeatureStore } from 'src/stores/featureStore';
import { useUploadQueueStore } from 'src/stores/uploadQueue';
import { v4 as uuid } from 'uuid';

import { dexieDb } from 'src/utils/indexedDb';

import ContentView from 'src/components/Content/Content';
import DamageHeader from 'src/components/Header/DamageHeader';
import CloseUp from 'src/components/icons/damage/CloseUp';
import Context from 'src/components/icons/damage/Context';

import useScrollUp from 'src/hooks/useScrollUp/useScrollUp';

const DamageCapture = () => {
  const [step, setStep] = useState(0);
  const { roomId } = useParams();
  const [images, setImages] = useState<string[]>([]);
  const [damageId, setDamageId] = useState<string | null>(null);
  const navigate = useNavigate();
  const allowDamage = useFeatureStore((state) => state.enableDamage);
  const damageLandingCopy = useEntityConfigStore(
    (state) => state.copy.damageLanding
  );
  const addImgToQueue = useUploadQueueStore((state) => state.add);

  const pictures =
    useLiveQuery(
      async () =>
        dexieDb.pictures
          .where('roomId')
          .equals(roomId || '')
          .and(
            (p) => p.imgType === 'damage-zoomed-in' && p.damageId === damageId
          )
          .toArray(),
      [roomId, damageId]
    ) || [];

  useScrollUp(step);

  const addImages = useCallback(async () => {
    if (!damageId) return;

    let index = 0;

    for (const img of images) {
      try {
        const pics = await dexieDb.pictures
          .where('roomId')
          .equals(roomId || '')
          .toArray();
        const maxId = Math.max(...pics.map((e) => e.id), 17) + 1;
        await dexieDb.pictures.put({
          id: maxId,
          damageId,
          content: img,
          uploadId: maxId,
          roomId,
          imgType: index === 0 ? 'damage-zoomed-in' : 'damage-zoomed-out',
        });

        navigator.onLine &&
          addImgToQueue({ id: maxId, abortController: new AbortController() });

        index++;
      } catch (e) {
        Sentry.captureException(e);
      }

      setImages([]);
    }
  }, [addImgToQueue, damageId, images, roomId]);

  useEffect(() => {
    setDamageId(uuid());
  }, []);

  useEffect(() => {
    if (images.length === 2) {
      addImages();
    }
  }, [images, addImages]);

  const handleNext = (img: string) => {
    if (step === 0 || step === 1) {
      setStep(step + 1);
      setImages((prevState) => [...prevState, img]);
    } else {
      setStep(0);
      setDamageId(uuid());
    }
  };

  const handleBack = () => {
    if (step === 0) {
      navigate(-1);
    } else {
      setStep(step - 1);
    }
  };

  return (
    <>
      {pictures.length <= 0 && (
        <DamageHeader
          hideBack={pictures.length >= 1}
          handleBack={handleBack}
          hideRestart={pictures.length >= 1 || !allowDamage}
          buttonRight={
            pictures.length <= 0 && !allowDamage ? <SkipButton /> : undefined
          }
        />
      )}
      <ContentView>
        {step === 0 && damageId && (
          <CaptureWrapper
            handleNext={handleNext}
            damageId={damageId}
            imgType="damage-zoomed-in"
            icon={<CloseUp className="stroke-icons-color" />}
            copy={damageLandingCopy.closeUp}
            damageType="close"
          />
        )}
        {step === 1 && damageId && (
          <CaptureWrapper
            handleNext={handleNext}
            damageId={damageId}
            imgType="damage-zoomed-out"
            icon={<Context className="stroke-icons-color" />}
            copy={damageLandingCopy.context}
            damageType="context"
          />
        )}
        {step === 2 && damageId && (
          <DamageReview handleNext={handleNext} damageId={damageId} />
        )}
      </ContentView>
    </>
  );
};

export default DamageCapture;
