import React, { useState } from 'react';
import { useEntityConfigStore } from 'src/stores/entityConfig';

import PhotoCapture from 'src/pages/Damage/DamageCapture/components/PhotoCapture/PhotoCapture';

import BaseButton from 'src/components/BaseButton/BaseButton';
import Footer from 'src/components/Footer/Footer';

import { eventNames } from 'src/utils/events';
import * as log from 'src/utils/logger';

type Copy = {
  title: string;
  subtitle: string;
  heading: string;
  takePhotoBtn: string;
};

type Props = {
  damageType: 'context' | 'close';
  handleNext: (data: string) => void;
  damageId: string;
  imgType: string;
  icon: React.ReactElement;
  copy: Copy;
};

const CaptureWrapper = ({
  handleNext,
  imgType,
  icon,
  copy,
  damageType,
}: Props) => {
  const [image, setImage] = useState<string | null>(null);
  const entityConfig = useEntityConfigStore((state) => state.config);

  const handleCapture = (data: string) => {
    setImage(data);
    log.hosta({
      event:
        imgType === 'damage-zoomed-in'
          ? eventNames.DAMAGE_CLOSE_UP_CAPTURED
          : eventNames.DAMAGE_CONTEXT_CAPTURED,
      data: {},
    });
  };

  return (
    <>
      <PhotoCapture
        icon={icon}
        title={copy.title}
        image={image}
        subtitle={copy.subtitle}
        imageType={copy.heading.toLowerCase()}
        handleCapture={handleCapture}
      />
      <Footer extraClassname="border-t bg-white pt-4">
        {!image && (
          <BaseButton
            lightBg
            type="capture"
            dataTestId={`${damageType}-photo-capture`}
            onCapture={handleCapture}
            icon="/icons/camera@2x.png"
            label={copy.takePhotoBtn}
            capFirstLetterOnly={entityConfig.capFirstLetterOnly}
          />
        )}
        {!!image && (
          <BaseButton
            dataTestId="damage-next"
            onClick={() => handleNext(image || '')}
            label="Next"
            lightBg
          />
        )}
      </Footer>
    </>
  );
};

export default CaptureWrapper;
