import './styles.css';
import Backdrop from '@mui/material/Backdrop';
import classnames from 'classnames';
import { useLiveQuery } from 'dexie-react-hooks';
import { indexOf } from 'lodash';
import React, { useEffect, useRef } from 'react';
import type { MouseEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useClaimInfoStore } from 'src/stores/claimInfo';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import { EffectCoverflow, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react';

import { dexieDb } from 'src/utils/indexedDb';

import BaseButton from 'src/components/BaseButton/BaseButton';
import Text from 'src/components/Text/Text';
import CheckCircle from 'src/components/icons/common/CheckCircle';

import { eventNames } from 'src/utils/events';
import * as log from 'src/utils/logger';

type Props = {
  open: boolean;
  roomId?: string;
  handleClose?: () => void;
};

export default function PhotosLoading({ open, handleClose, roomId }: Props) {
  const params = useParams();
  const navigate = useNavigate();
  const recaptureInfo = useClaimInfoStore((state) => state.recaptureInfo);
  const returnId = useClaimInfoStore((state) => state.returnId);
  const setReturnId = useClaimInfoStore((state) => state.setReturnId);
  const isRoomSubmitted = useClaimInfoStore((state) => state.isRoomSubmitted);
  const claimInfo = useClaimInfoStore();

  const swiper = useRef<SwiperRef>(null);
  const pictures = useLiveQuery(
    async () =>
      dexieDb.pictures
        .where('roomId')
        .equals(roomId || '')
        .toArray(),
    [roomId]
  );

  useEffect(() => {
    for (const pic of pictures || []) {
      if (!pic.uploaded) {
        swiper.current?.swiper.slideTo(indexOf(pictures, pic));
        return;
      }
    }
  }, [pictures]);

  const handleReturn = () => {
    log.info({
      event: eventNames.RETURN_TO_URL,
      data: { returnId: returnId },
    });

    log.hosta({
      event: eventNames.CLAIM_CAPTURE_COMPLETED,
      data: {},
    });

    // Reset the return id on returning to the self-service portal
    setReturnId();
    window.location.href =
      process.env.REACT_APP_BASE_URL + '/#/app/allroom/' + returnId;
  };

  const handleAddAnotherRoom = () => {
    log.info({
      event: eventNames.ADD_ANOTHER_ROOM,
      data: { previousRoomId: params.roomId },
    });
    navigate(`/${params.captureType}/${params.claimId}/room-select`);
  };

  const handleRecapture = () => {
    navigate(`/recapture/${params.claimId}`);
  };

  function handleTakeSurvey(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    window.open(claimInfo?.surveyLink, '_blank');
  }

  const uploaded = pictures?.filter((pic) => pic.uploaded)?.length || 0;
  const roomSubmitted = uploaded === pictures?.length;
  const hasDamage =
    pictures?.filter((e) => e.imgType === 'overviewImage').length !==
    pictures?.length;

  return (
    <Backdrop
      sx={{
        background: 'rgba(256, 256, 256, 0.9)',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        overflowY: 'scroll',
      }}
      open={open}
      onClick={() => {
        if (typeof handleClose === 'function') {
          handleClose();
        }
      }}
      data-testid="loading-component"
    >
      <div className="flex flex-col min-h-full pt-14 px-4 mx-w-screen w-screen justify-between">
        <div
          className={classnames(
            'overflow-hidden transition-opacity duration-1000 ease-in',
            {
              'opacity-0 invisible absolute': !roomSubmitted,
              'opacity-100 visible relative': roomSubmitted,
            }
          )}
        >
          <Text variant="title" testId="room-submitted-title">
            {hasDamage
              ? 'Your damage and room photos have been uploaded!'
              : 'Your room photos have been uploaded!'}
          </Text>

          <div className="w-full mt-6 relative">
            <Swiper
              slidesPerView={3}
              spaceBetween={5}
              autoplay={{ delay: 5000 }}
              modules={[Autoplay]}
              loop={true}
              className="submited-swiper"
            >
              {pictures?.map((picture) => (
                <SwiperSlide key={picture.id}>
                  <img
                    alt="wall-and-damage-images"
                    src={picture.content}
                    width="100%"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="flex absolute h-full w-full top-0 z-[100] justify-between overflow-hidden">
              <div className="w-[20%] bg-white-gradient-left h-full"></div>
              <div className="w-[20%] bg-white-gradient-right h-full"></div>
            </div>
          </div>

          <div className="w-full flex justify-center items-center mt-6 flex-col">
            <CheckCircle className="fill-icons-color" />
            <Text
              variant="paragraph"
              extraClassname="text-sm weight-bold text-icons-color font-semibold pb-8"
            >
              {uploaded} of {pictures?.length} Images Uploaded
            </Text>
          </div>
        </div>

        <div
          className={classnames('w-full', {
            block: !roomSubmitted,
            hidden: roomSubmitted,
          })}
        >
          <Text variant="title">
            {pictures?.length} photos of your {recaptureInfo?.roomType} <br />{' '}
            are being uploaded
          </Text>

          <Swiper
            ref={swiper}
            effect="coverflow"
            grabCursor={false}
            centeredSlides={true}
            slidesPerView="auto"
            coverflowEffect={{
              rotate: 0,
              stretch: -20,
              depth: 100,
              modifier: 2,
              slideShadows: false,
            }}
            allowTouchMove={false}
            mousewheel={false}
            pagination={true}
            modules={[EffectCoverflow]}
            className="loader-swiper"
          >
            {pictures?.map((picture) => (
              <SwiperSlide key={picture.id}>
                <div
                  className="image w-full h-full bg-cover bg-center noSwipingClass"
                  style={{ backgroundImage: `url(${picture.content})` }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <Text
            variant="paragraph"
            extraClassname="text-sm weight-bold text-icons-color font-semibold"
          >
            Uploading Image {uploaded + 1} of {pictures?.length}
          </Text>
        </div>

        <div>
          <div style={{ display: !roomSubmitted ? 'block' : 'none' }}>
            <Text variant="paragraph" extraClassname="my-4 text-x">
              Do not close or leave this page as
              <br />
              your photos are being uploaded.
            </Text>
            <Text variant="paragraph" extraClassname="my-4 text-sm">
              Uploads usually take one minute or less.
            </Text>
          </div>

          <div style={{ display: roomSubmitted ? 'block' : 'none' }}>
            <Text variant="paragraph" extraClassname="mb-3 text-x">
              Your photos are being reviewed. <br />
              If any of your photos cannot be <br />
              processed, you will receive a text message <br />
              within 10 minutes with next steps.
            </Text>

            <Text
              variant="paragraph"
              extraClassname="mb-6 text-x font-semibold"
            >
              You may close this window if you do not <br />
              have anymore photos to capture.
            </Text>
          </div>

          <div className="pb-4">
            {claimInfo?.surveyLink && (
              <>
                <div style={{ display: 'block' }}>
                  <Text variant="paragraph" extraClassname="mb-3 text-x">
                    Your feedback on this experience is important to us. Please
                    spare a minute to share your thoughts.
                  </Text>
                </div>
                <BaseButton
                  outlined
                  className="mb-2"
                  dataTestId="take-survey"
                  label="Take Survey"
                  disabled={!roomSubmitted || !isRoomSubmitted}
                  onClick={handleTakeSurvey}
                />
              </>
            )}
            {recaptureInfo?.isRecapture && (
              <BaseButton
                className="mb-2"
                dataTestId="in-done-with-room-capture-btn"
                label="I’m Done"
                onClick={handleRecapture}
              />
            )}
            {!recaptureInfo?.isRecapture && returnId ? (
              <BaseButton
                className="mb-2"
                dataTestId="in-done-with-room-capture-btn"
                label="I’m Done"
                disabled={!roomSubmitted}
                onClick={handleReturn}
              />
            ) : null}
            <BaseButton
              dataTestId="add-another-room-btn"
              label="I Have Damage in Another Room"
              disabled={!roomSubmitted || !isRoomSubmitted}
              onClick={handleAddAnotherRoom}
            />
          </div>
        </div>
      </div>
    </Backdrop>
  );
}
