import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import { TransitionProps } from '@mui/material/transitions';
import type { AxiosError } from 'axios';
import classnames from 'classnames';
import React from 'react';
import { useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as api from 'src/REST/capture';
import { useClaimInfoStore } from 'src/stores/claimInfo';
import { useEntityConfigStore } from 'src/stores/entityConfig';
import { useFeatureStore } from 'src/stores/featureStore';
import texts, { analytics } from 'src/texts/texts';
import { v4 as uuid } from 'uuid';

import { dexieDb } from 'src/utils/indexedDb';

import BaseButton from 'src/components/BaseButton/BaseButton';
import ContentView from 'src/components/Content/Content';
import Footer from 'src/components/Footer/Footer';
import DamageHeader from 'src/components/Header/DamageHeader';
import Loading from 'src/components/Loading/Loading';
import Modal from 'src/components/Modal/Modal';
import SelectionGrid, {
  GridElement,
} from 'src/components/SelectionGrid/SelectionGrid';
import Text from 'src/components/Text/Text';

import { eventNames } from 'src/utils/events';
import * as log from 'src/utils/logger';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const rooms = [
  texts.bathroom,
  texts.bedroom,
  texts.kitchen,
  texts.basement,
  texts.living,
  texts.laundry,
  texts.garage,
  texts.dining,
  texts.other,
];

const RoomSelectPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [roomType, setRoomType] = useState('');
  const [open, setOpen] = useState(false);
  const collectRoomName = useFeatureStore((state) => state.collectRoomName);
  const setCaptureId = useClaimInfoStore((state) => state.setCaptureId);
  const roomSelectCopy = useEntityConfigStore((state) => state.copy.roomSelect);
  const captureId = useClaimInfoStore((state) => state.captureId);
  const setRoomId = useClaimInfoStore((state) => state.setRoomId);
  const roomId = useClaimInfoStore((state) => state.roomId);
  const setIsRoomSubmitted = useClaimInfoStore(
    (state) => state.setIsRoomSubmitted
  );

  const onSelect = (selectedRoom: string) => () => {
    log.event({
      event: eventNames.SELECT_ROOM,
      data: {
        page: 'Room Select Page',
        env: process.env.REACT_APP_ENV,
        selectedRoom,
      },
    });
    setRoomType(selectedRoom);
  };

  const handleClearDb = async () => {
    await dexieDb.pictures.clear();
  };

  const navigateToNext = useCallback(
    async (givenRomId?: string) => {
      navigate(
        `/${params.captureType}/${params.claimId}/${givenRomId || roomId}/damage-example`
      );
    },
    [navigate, params.captureType, params.claimId, roomId]
  );

  const handleRoomDesc = async (roomName: string, roomDesc: string) => {
    const roomNameTrim = roomName?.trim();

    try {
      await api.updateRoom(params.claimId || '', roomId || '', {
        ...(roomType && { roomType }),
        ...(roomDesc && { roomDesc }),
        ...(roomName && { roomName: roomNameTrim }),
      });

      log.hosta({
        event: eventNames.ROOM_TYPE_SELECTED,
        data: { roomType },
      });

      log.hosta({
        event: roomNameTrim
          ? eventNames.ROOM_NAME_ENTERED
          : eventNames.ROOM_NAME_SKIPPED,
        data: roomNameTrim ? { roomName: roomNameTrim } : {},
      });

      log.hosta({
        event: roomDesc
          ? eventNames.DAMAGE_DESCRIPTION_ENTERED
          : eventNames.DAMAGE_DESCRIPTION_SKIPPED,
        data: roomDesc ? { damage_description: roomDesc } : {},
      });

      log.info({
        event: eventNames.ROOM_SELECTED_SUCCESS,
        data: {
          type: roomType,
          template: 'roomTypeSelection',
          header: 'Room Type Selection :house:',
          roomDesc: collectRoomName ? roomDesc || false : 'undefined',
          roomName: collectRoomName
            ? Boolean(roomNameTrim)
              ? roomNameTrim
              : false
            : 'undefined',
          roomId: roomId,
        },
      });

      await navigateToNext();
    } catch (e) {
      log.error({
        event: eventNames.ROOM_DESCRIPTIONS,
        ignoreSlack: false,
        data: { header: 'Room type labeling error', error: e },
      });
    }
  };

  const handleSelectRoom = async () => {
    const captId = !captureId ? uuid() : captureId;
    !captureId && setCaptureId(captId);
    setLoad(true);

    try {
      await handleClearDb();
      const { data } = await api.addNewRoom(params.claimId || '', captId);
      setIsRoomSubmitted(false);

      if (data) {
        setRoomId(data.roomId);

        if (collectRoomName) {
          setOpen(true);
        } else {
          await api.updateRoom(params.claimId || '', data.roomId, { roomType });
          log.hosta({
            event: eventNames.ROOM_TYPE_SELECTED,
            data: { roomType },
          });
          log.info({
            event: eventNames.ROOM_SELECTED_SUCCESS,
            data: {
              type: roomType,
              template: 'roomTypeSelection',
              header: 'Room Type Selection :house:',
              roomId: data.roomId,
            },
          });
          await navigateToNext(data.roomId);
        }
      }
    } catch (e) {
      const error = e as AxiosError;
      log.error({
        event: eventNames.ROOM_SELECTED_FAIL,
        ignoreSlack: false,
        data: { header: 'Room type selection error', error: e },
      });
      if (error?.response) {
        if (error?.response?.status >= 400 && error?.response?.status < 500) {
          setError(error?.response?.data as string);
        }
      }
      setRoomType('');
      setError('Unable to Continue. Please contact Administrator');
    } finally {
      setLoad(false);
    }
  };

  const handleClose = () => setLoad(false);

  const elementsWithData = rooms.map((room, index) => {
    const roomType = room.split(' ')?.[0] || 'other';
    return {
      text: room,
      name: roomType,
      className: classnames('basis-[calc(33%_-_5px)] pt-[calc(33%_-_5px)]', {
        'mt-2': index > 2,
        'mr-1': index % 3 === 0,
        'mx-1': index % 3 === 1,
        'ml-1': index % 3 === 2,
      }),
    };
  });

  return (
    <>
      <DamageHeader hideRestart />
      <RoomName
        elements={elementsWithData.filter((e) => e.text === roomType)}
        roomType={roomType}
        open={open}
        handleClose={() => setOpen(false)}
        handleSave={handleRoomDesc}
      />
      <ContentView>
        <div className="w-full flex-1 flex flex-col items-center h-full pb-24">
          <div className="w-full desktops:w-desktop px-4 desktops:px-0 max-w-desktop pb-8">
            <div className="pb-3">
              <Text variant="title">{roomSelectCopy.title}</Text>
            </div>
            <Text variant="subtitle">{roomSelectCopy.subtitle}</Text>
          </div>

          <SelectionGrid
            checked={roomType}
            onClick={onSelect}
            elements={elementsWithData}
          />

          <Footer extraClassname="border-t bg-white pt-4">
            <BaseButton
              lightBg
              disabled={!roomType}
              name={analytics.selectRoom}
              onClick={handleSelectRoom}
              label={texts.next}
              dataTestId="handle-select-room"
            />
          </Footer>
          {error && (
            <Modal
              handleClose={() => setError(null)}
              title="Caution"
              text={error}
              buttonText="Got it"
            />
          )}
          <Loading open={load} handleClose={handleClose} />
        </div>
      </ContentView>
    </>
  );
};

const RoomName = ({
  open,
  handleClose,
  handleSave,
  roomType,
  elements,
}: {
  open: boolean;
  handleClose: () => void;
  handleSave: (value: string, description: string) => void;
  roomType: string;
  elements: GridElement[];
}) => {
  const handleClick = (roomData: string) => () => {};

  const [value, setValue] = useState('');
  const [desc, setDesc] = useState('');

  const isValid = (type: 'name' | 'desc') => {
    const maxLength = type === 'name' ? 35 : 250;
    const str = type === 'name' ? value : desc;
    const regex = type === 'name' ? /^[a-zA-Z0-9\s]+$/ : /^[a-zA-Z0-9\s.]+$/;
    if (str) {
      return str.length <= maxLength && regex.test(str);
    }
    return true;
  };

  const getErrorMessage = (type: 'name' | 'desc') => {
    const maxLength = type === 'name' ? 35 : 250;
    const regex = type === 'name' ? /^[a-zA-Z0-9\s]+$/ : /^[a-zA-Z0-9\s.]+$/;
    const str = type === 'name' ? value : desc;
    if (!isValid(type)) {
      if (str.length > maxLength)
        return `Please enter a value where Maximum Length is less than ${maxLength} characters.`;
      if (!regex.test(str))
        return type === 'name'
          ? 'Please enter a value with only Alpha Numeric Characters'
          : 'Please enter a value with no special characters';
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <DamageHeader hideRestart handleBack={handleClose} />
      <ContentView>
        <div className="w-full flex flex-col pb-24 gap-10">
          <SelectionGrid
            checked={roomType}
            onClick={handleClick}
            elements={elements}
          />
          <div className="flex flex-col w-full">
            <Text extraClassname="text-justify" variant="title">
              Give this room a label
            </Text>
            <Text extraClassname="text-justify" variant="paragraph">
              Labels help if there are multiple rooms in this category such as
              bathrooms or bedrooms. Example label: My son's bedroom
            </Text>
            <TextField
              required
              placeholder="My Label"
              value={value}
              onChange={(evt) => setValue(evt.target.value)}
              error={!isValid('name')}
              helperText={getErrorMessage('name')}
            />
          </div>
          <div className="flex flex-col w-full">
            <Text extraClassname="text-justify" variant="title">
              Summarize damage in this room
            </Text>
            <small>(Optional)</small>
            <TextField
              placeholder="Describe in 1-2 sentences what happened. Your input will be shared with your insurance adjuster."
              multiline
              rows={4}
              value={desc}
              onChange={(evt) => setDesc(evt.target.value)}
              error={!isValid('desc')}
              helperText={getErrorMessage('desc')}
            />
          </div>
        </div>
        <Footer extraClassname="border-t bg-white pt-4">
          <BaseButton
            lightBg
            disabled={!(isValid('name') && isValid('desc'))}
            onClick={() => handleSave(value, desc)}
            label={texts.next}
            dataTestId="handle-add-room-description"
          />
        </Footer>
      </ContentView>
    </Dialog>
  );
};

export default RoomSelectPage;
