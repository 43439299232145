import { Warning } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getImageLink, submitRoom } from 'src/REST/capture';
import { useEventStore } from 'src/stores/eventStore';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { EffectCoverflow, FreeMode } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import Text from 'src/components/Text/Text';

import { base64ToFile, getBase64StringFromDataURL } from 'src/utils/utils';

interface LoaderProps {
  room: any;
  handleSubmit: () => void;
}

const Loader: React.FC<LoaderProps> = ({ room, handleSubmit }) => {
  const { linkId } = useParams();
  const [active, setActive] = useState(0);
  const swipeRef = useRef<SwiperRef>(null);
  const eventStore = useEventStore();
  const photos = room?.photos?.reduce(
    (acc: string[], image: any, index: number) => {
      return [...acc, image.left, image.right, image.one].filter((e) => e);
    },
    [] as string[]
  );

  useEffect(() => {
    const photo = photos?.[active];
    if (photo) {
      const file = base64ToFile(getBase64StringFromDataURL(photo));
      const recaptureKey = 'RECAPTURE_' + room?.imageQaRejectionCount;
      getImageLink(
        linkId || '',
        room?.roomId,
        file,
        'jpeg',
        (active + 1).toString(),
        undefined,
        undefined,
        {},
        [],
        undefined,
        recaptureKey
      )
        .then(() => {
          swipeRef.current?.swiper?.slideNext();
          setActive(active + 1);
        })
        .catch((error) => {
          console.error('Error uploading image', error);
          eventStore.setError(
            error?.data?.message || 'An error occurred',
            true
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    active,
    photos,
    room?.roomId,
    room?.imageQaRejectionCount,
    room?.roomType,
    linkId,
  ]);

  const handleSubmitRoom = () => {
    eventStore.setLoading(true);
    submitRoom(linkId || '', room?.roomId, {})
      .then(() => {
        console.log('Room submitted');
        eventStore.setLoading(false);
        handleSubmit();
      })
      .catch((error) => {
        console.error('Error submitting room', error);
        eventStore.setError(error?.data?.message || 'An error occurred', true);
        eventStore.setLoading(false);
      });
  };

  if (active < photos?.length) {
    return (
      <div className="flex flex-col w-full items-center h-full max-w-[500px] m-auto">
        <div className="flex flex-col h-full justify-between w-full">
          <div className="flex flex-col w-full h-full pt-6">
            <Text align="center" variant="title" extraClassname="font-semibold">
              {photos.length} photos for {room?.roomName} are being uploaded
            </Text>
            <div className="h-64 w-full">
              <Swiper
                initialSlide={active}
                ref={swipeRef}
                effect="coverflow"
                slidesPerView={3}
                centeredSlides={true}
                coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: false,
                }}
                loop={false}
                modules={[EffectCoverflow]}
                className="mySwiper"
              >
                {photos.map((image: string, index: number) => (
                  <SwiperSlide key={index}>
                    <img
                      className={`w-full h-auto ${active > index && 'opacity-50'}`}
                      src={image}
                      alt={`Upload ${index}`}
                    />
                    <div className="absolute opacity-90 h-full w-full flex items-center justify-center">
                      {active > index && <CheckCircleIcon color="primary" />}
                      {active === index && <CircularProgress color="primary" />}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <p className="text-center">
              Uploading Image {active + 1} of {photos.length}
            </p>
          </div>
          <div>
            <p className="text-center">
              <Warning color="warning" /> Do not close or leave this page as
              your photos are being uploaded. Uploads usually take one minute or
              less.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full items-center h-full max-w-[500px] mx-auto">
      <div className="flex flex-col h-full justify-between w-full">
        <div className="flex flex-col w-full h-full pt-6">
          <Text align="center" variant="title" extraClassname="font-semibold">
            Your photos have been submitted!
          </Text>
          <div className="h-64 w-full">
            <Swiper
              slidesPerView={3}
              spaceBetween={30}
              freeMode={true}
              modules={[FreeMode]}
              className="mySwiper"
            >
              {photos.map((image: string, index: number) => (
                <SwiperSlide key={index}>
                  <img
                    className="w-full h-auto"
                    src={image}
                    alt={`Upload ${index}`}
                  />
                  <div className="absolute opacity-90 h-full w-full flex items-center justify-center">
                    {active === index && <CircularProgress color="primary" />}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="flex flex-col items-center">
              <CheckCircleIcon fontSize="large" color="primary" />
              <h6>
                {active} of {photos.length} Images Uploaded
              </h6>
            </div>
          </div>
        </div>
        <div className="w-full p-2">
          <Button onClick={handleSubmitRoom} fullWidth variant="contained">
            Done
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Loader;
